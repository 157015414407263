<template>
  <b-card-code :title="$t('stands_title')">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t("search") }}</label>
          <b-form-input
            v-model="searchTerm"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="stand_list"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'pavilion', type: 'asc' },
      }"
      :search-options="{
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      styleClass="vgt-table table-hover"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Pavilion -->
        <span v-if="props.column.field == 'pavilion'">
          <span> {{ props.row.pavilion_ref }}</span>
        </span>
        <!-- Column: Name -->
        <span v-if="props.column.field == 'name'" class="row-logo">
          <div>
            <b-img :src="props.row.logo_url" />
          </div>
          <a
            :href="`/stands/${props.row.id}`"
            style="font-weight: bold;"
            v-if="props.row.name != ''"
            >{{ props.row.name }}</a
          >
        </span>

        <!-- Column: Gestore -->
        <span v-if="props.column.field == 'Manager'">
          <span v-if="props.row.manager_email != ''">
            {{ props.row.manager_email }}
          </span>
        </span>

        <!-- Column: Posizione -->
        <span v-if="props.column.field == 'Position'">
          <span> {{ props.row.external_ref }}</span>
        </span>

        <!-- Column: Type -->
        <span v-if="props.column.field == 'Type'">
          <span> {{ props.row.type.label }}</span>
        </span>

        <!-- Column: Pin -->
        <span v-if="props.column.field == 'Pin'">
          <span
            :class="
              props.row.active_pinpoints > 0 ? 'text-success' : 'text-danger'
            "
          >
            {{ props.row.active_pinpoints }}</span
          ><span>/{{ props.row.type.pinpoints.length }}</span>
        </span>

        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge pill v-if="props.row.active" variant="success">
            {{ $t("status_active") }}
          </b-badge>
          <b-badge pill v-else variant="danger">
            {{ $t("status_inactive") }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="goToEditStand(props.row.id)">
                <feather-icon icon="FileTextIcon" class="mr-50" />
                <span>{{ $t("edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteStandContent(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span> {{ $t("empty") }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{ $t("showing") }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">
              {{ $t("showing_pt2", { total_entries }) }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-card-code>
      <b-img
        :src="require('@/assets/images/pages/list-stand/map.jpg')"
        width="800"
      ></b-img>
    </b-card-code>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BImg,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";
import { emptyStand, getAllStands } from "@api/stands";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      total_props: null,
      pageLength: 10,
      searchTerm: "",
      columns: [
        {
          field: "pavilion",
          label: this.$t("pavilion"),
          sortable: true,
          sortFn: this.sortPavilionRef,
          width: "50px",
        },
        {
          field: "name",
          label: this.$t("field_label_name"),
          sortable: true,
          sortFn: this.sortNameStand,
        },
        {
          field: "Manager",
          label: this.$t("field_label_manager"),
          sortable: false,
        },
        {
          field: "Position",
          label: this.$t("Position"),
          sortable: true,
          sortFn: this.sortPosition,
        },
        {
          field: "Type",
          label: this.$t("field_label_type"),
          sortable: true,
          sortFn: this.sortPavilionType,
        },
        { field: "Pin", label: this.$t("field_label_pin"), sortable: false },
        {
          field: "Media",
          label: this.$t("field_label_media"),
          sortable: false,
        },
        {
          field: "status",
          label: this.$t("field_label_status"),
          sortable: false,
        },
        {
          field: "action",
          label: this.$t("field_label_actions"),
          sortable: false,
        },
      ],
      stand_list: [],
      total_entries: null,
    };
  },
  created() {
    //Carica lista stand
    this.getData();
  },
  mounted() {
    document.getElementById(
      "breadcrumbsPageTitle"
    ).innerHTML = this.$route.meta.pageTitle;
  },
  directives: {
    Ripple,
  },
  methods: {
    getData() {
      let $this = this;
      getAllStands().then((data) => {
        $this.stand_list = data;
        $this.total_entries = $this.stand_list.length;
      });
    },
    sortPavilionRef(x, y, col, rowX, rowY) {
      x = rowX.pavilion_ref;
      y = rowY.pavilion_ref;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortNameStand(x, y, col, rowX, rowY) {
      x = rowX.name;
      y = rowY.name;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortPosition(x, y, col, rowX, rowY) {
      x = rowX.external_ref;
      y = rowY.external_ref;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    sortPavilionType(x, y, col, rowX, rowY) {
      x = rowX.type.label;
      y = rowY.type.label;
      return x < y ? -1 : x > y ? 1 : 0;
    },
    goToEditStand(id) {
      this.$router.push({ path: `/stands/${id}`, params: { id: id } });
    },
    deleteStandContent(id) {
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text: this.$t("alert_content_text"),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.$t("alert_confirm_button"),
        cancelButtonText: this.$t("alert_cancel_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: this.$t("alert_title_success"),
              text: this.$t("alert_success_text"),
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            emptyStand(id)
              .then((data) => {
                this.getData();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
